.homepage-root {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.home-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subtitle {
  font-size: 16px; /* Default font size */
}

@media (max-width: 600px) {
  .subtitle {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 400px) {
  .subtitle {
    font-size: 12px; /* Further adjust font size for even smaller screens */
  }
}
