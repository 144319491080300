.subject-page-root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

.subject {
    width: 450px;
    min-width: 300px;
    max-width: 600px;
}

@media (max-width: 700px) {
  .subject {
    width: 100%;
    max-width: 300px;
  }
}
