.class-page-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.course-hero {
  display: flex;
}

.course-hero-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.course-hero-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.review-column {
  gap: 15px;
  max-width: 550px;
  width: 90%;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}

.review-column .review:last-child {
  margin-bottom: 10px;
}


.review {
  background-color: white;
  min-width: '350px';
  max-width: '500px';
  width: 100%;
}

.review-header {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.review-difficulty {
  width: auto;
  display: flex;
}

.review-date {
  justify-self: flex-end;
  margin-left: auto;
}

.review-comment {
  padding: 15px;
  line-height: 1.5em;
}

.control-bar {
  display: flex;
  padding: 10px;
}

.helpful {
  display: flex;
  align-items: center;
}

.helpful p {
  margin-right: 10px;
}
